.footer-bg {
  height: 175px;
  background-image: url("../img/babolygo-foooter-bg.svg");
   background-position: center center;
   background-repeat: no-repeat;
   background-size: 1140px 168px;
}



.footer-bird-left {
  left: -90px;
  top: -40px;
}
.footer-bird-right {
  right: -90px;
  top: -40px;
}
 

#sp-tabpro-wrapper_1118.sp-tab__lay-default ul > li > label, #sp-tabpro-wrapper_1118.sp-tab__lay-default ul > li > a, #sp-tabpro-wrapper_1118.sp-tab__lay-default ul .sp-tab__nav-item {
  border-radius: 80px !important;
  white-space: nowrap;
}
#sp-tabpro-wrapper_1118.sp-tab__lay-default ul .sp-tab__nav-item {
  margin-right: 1rem !important;
}
#sp-tabpro-wrapper_1118 .sp-tab__nav-tabs .sp-tab__nav-link {
height: auto !important;
&:hover {
  height: auto !important;
}
}
.sp-tab__nav-item label {
  margin-bottom: 0 !important;
}
#sp-tabpro-wrapper_1118 .sp-tab__nav-tabs .sp-tab__nav-link.sp-tab__active .sp-tab__tab_title, #sp-tabpro-wrapper_1118.sp-tab__lay-default label .sp-tab__card-header {
}
#sp-tabpro-wrapper_1118.sp-tab__lay-default ul .sp-tab__nav-item .sp-tab__tab_title, #sp-tabpro-wrapper_1118.sp-tab__lay-default label.collapsed .sp-tab__card-header {
  color: $color-primary !important;
}
#sp-tabpro-wrapper_1118.sp-tab__lay-default .sp-tab__tab-content > .sp-tab__tab-pane {
color: $color-primary !important;
}
#sp-tabpro-wrapper_1118 {
  #sp-tab__ul {
    margin-bottom: 1rem ;
    overflow-x: auto;
    padding: 1rem 0;
    flex-wrap: nowrap;
    @include media-breakpoint-up(md) {
      margin-bottom: 2rem ;
    };
  }
}


  // .nav-item {
  //   flex: 1;
  //   margin-bottom: 0px;
  //   border-right: 2px solid $gray-200;
  //   border-left: 2px solid $gray-200;
  //   &:last-child {
  //     border-right: none;
  //   }
  //   &:first-child {
  //     border-right: none;
  //     border-left: none;
  //   }
  //   &:nth-of-type(2) {
  //     border-left: none;
  //   }
  // }
// }

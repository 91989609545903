.card {
  border: none;
}
.card-header, .card-footer {
  background-color: transparent;
}
.card-footer,  .card-header{
  padding: 1rem;
}

.accordion {
  .card {
    background-color: transparent;
    border: 2px solid white;
    // border-radius: 1rem;

    .btn {
      box-shadow: none;
      padding: .875rem;
    }
    .card-header {
      padding: 0;
      border-bottom: 0;
    }
    .card-body {
      padding: 1rem 0;
      p {
        color: $color-primary;
      }
    }
  }
}

.accordion .card:first-of-type  {
    // border-bottom: 2px solid white;
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  // border-bottom: 2px solid white;
 }


.card-img--new {
  border-radius: 0;
  @include media-breakpoint-up(lg) {
    height: 100%;
  };
  @include media-breakpoint-up(xl) {
    height: auto;
  };
}
.card-col-height {
overflow: hidden;
height: auto;
border-top-left-radius: .5rem;
border-top-right-radius: .5rem;
border-bottom-left-radius: 0rem;
border-bottom-right-radius: 0rem;

@include media-breakpoint-up(md) {
  height: 355px;

};
@include media-breakpoint-up(lg) {
  border-top-left-radius: .5rem;
  border-top-right-radius: 0rem;
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: 0rem;

};

}
.card--zoom {
  transition: all 0.3s;
  height: 177px;
  top:0px;
  &:hover {
    height: 250px;
    top: -14%;
    position: relative;
    // transform: scaleY(1.5);
    // transform-origin: bottom;
  }
  // &:hover .card-footer {
  //   transform: scaleY(1);
  // }
}
.card-img-overlay--hidden {
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 2;
  &:hover {
    opacity: 1
  }
}
.card-body-overflow {
      max-height: 355px;
      @include media-breakpoint-up (lg) {
        height: 355px;
        overflow: auto;

      };
}
.card-body-overflow-2 {
      max-height: 405px;
      @include media-breakpoint-up (lg) {
        height: 405px;
        overflow: auto;

      };
}

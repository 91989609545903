body{
	text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	padding: 0;
	color: $color-primary ;
	font-family: $font-primary;
	font-size: 1rem;
	background: $color-tertiary ;
}
.no-photo  {
	.fa-user-circle{
		width: 40px;
		height: 40px;
		font-size: 2.5rem !important;
	}
}
blockquote.blockquote {
	margin-top: 3rem;
}
blockquote.blockquote:before {
	font-size: 8rem;
	content: "“";
	color: $color-secondary;
	position: absolute;
	top: -20px;
	left: 0;
	right: 0;
}
.wave{
	// clip-path: url(#wave-top);
	// height: 100vh;
	// width: 100%
	height: 115px;
	background-image: url("../img/babolygo-wave.svg");
	background-position: center center;
	@include media-breakpoint-up (md) {
		height: 185px;
		background-image: url("../img/babolygo-wave.svg");
		background-position: -300px 0px;
	}
}

.wave--down{
	background-image: url("../img/babolygo-wave-down.svg");
	background-position: -1025px 0px;
	height: 115px;
	@include media-breakpoint-up (sm) {
		background-position: -950px 0px;
	}
	@include media-breakpoint-up (md) {
		background-position: -850px 0px;
		height: 159px;
	}
}
.wave--team{
	background-image: url("../img/babolygo-wave-divider.svg");
	background-position: center center;
	background-repeat: no-repeat;
	height: 24px;
	margin-bottom: 1.5rem;
	@include media-breakpoint-up (md) {
		margin-bottom: 3rem;
	}
}
.butterfly {
	right: -30px;
	top: -39px;
	@include media-breakpoint-up (lg) {
		top: -28px;
	}
	@include media-breakpoint-up (xl) {
		top: -39px;
	}
}
.babolygo-gfx {
	width: 100%;
	height: auto;
	margin-bottom: 2rem;
	@include media-breakpoint-up (lg) {
		height: 458px;
		width: 401px;
		margin-bottom: 0;
	}
}
.mt-n6 {
	margin-top: 0rem;
	@include media-breakpoint-up (lg) {
		margin-top: -2rem;
	};
}
.rounded-top-right {
	border-top-right-radius: .5rem !important;
}
.rounded-bottom-right {
	border-bottom-right-radius: .5rem !important;
}
.rounded-left {
}

.letter-spacing {
	letter-spacing: 7px;
	text-shadow: 0 2px 30px rgba(0,0,0,0.50);
}
.section--bm {
	padding-bottom: 4rem;
	@include media-breakpoint-up (md) {
		padding-bottom: 4.5rem;
	};
}
.section--bm-first{
	margin-bottom: 4.5rem;
	position: relative;
	@include media-breakpoint-up (md) {
		margin-bottom: 4.5rem;
	};
}
// .section--bm *:last-child *:last-child {
// 	margin-bottom: 0;
// }
.section--p {
		padding-top: 2.2rem;
		padding-bottom: 2.5rem;
}
.bg-black {
 background-color: $black;
}
.bg-tertiary  {
 background-color: $color-tertiary;
}
.box-shadow-bg {
	box-shadow: 5px 0px 0px $color-primary, -5px 0px 0px $color-primary
}
/* scaleUp */
.owl-fadeUp-in {
  -webkit-animation: scaleUpFrom .5s ease both;
  -moz-animation: scaleUpFrom .5s ease both;
  animation: scaleUpFrom .5s ease both;
}
.owl-fadeUp-in-slow {
  -webkit-animation: scaleUpFrom 2s ease both;
  -moz-animation: scaleUpFrom 2s ease both;
  animation: scaleUpFrom 2s ease both;
}

.owl-fadeUp-out {
  -webkit-animation: scaleUpTo .5s ease both;
  -moz-animation: scaleUpTo .5s ease both;
  animation: scaleUpTo .5s ease both;
}
.letter-spacing-anim {
	-webkit-animation: in 1s ease-out both;
	-moz-animation: in 1s ease-out both;
	animation: in 1s ease-out both;
}

@keyframes in{
  0%{
    letter-spacing: 55px;
    opacity: 0;
  }
  100%{
    letter-spacing: 7px;
    opacity: 1;
  }
}

@-webkit-keyframes stretchFrom {
  from { opacity: 0; -webkit-transform: scale(1.2); }
}

@-webkit-keyframes scaleUpFrom {
  from { opacity: 0; -webkit-transform: scale(1.2); }
}
@-moz-keyframes scaleUpFrom {
  from { opacity: 0; -moz-transform: scale(1.2); }
}
@keyframes scaleUpFrom {
  from { opacity: 0; transform: scale(1.2); }
}

@-webkit-keyframes scaleUpTo {
  to { opacity: 0; -webkit-transform: scale(1.2); }
}
@-moz-keyframes scaleUpTo {
  to { opacity: 0; -moz-transform: scale(1.2); }
}
@keyframes scaleUpTo {
  to { opacity: 0; transform: scale(1.2); }
}

/* dropdown animation */

.animate {
	animation-duration: 0.3s;
	-webkit-animation-duration: 0.3s;
	animation-fill-mode: both;
	-webkit-animation-fill-mode: both;
}
@keyframes slideIn {
  0% {
    transform: translateY(60px);
    opacity: 0;
  }
  100% {
    transform:translateY(42px);
    opacity: 1;
  }
  0% {
    transform: translateY(60px);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translateY(60px);
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(42px);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(60px);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

.bg-gray {
	background-color: $gray-550;
}

.owl-all-device {
	.owl-item {
		background: black;
	}
	.item {
		opacity: 1;
	}
}
.main-subpage {
	margin-top: 4.1rem;
	@include media-breakpoint-up(lg) {
		margin-top: 5.5rem;
	};
}
.text-white-80 {
	color: rgba(255,255,255,0.8)!important;
}
.bg {
	padding: 0;
	@include media-breakpoint-up(md) {
		background-size: cover;
		background-position: center;
		padding: 3.5rem 2.5rem;
	};
}

.hasTopBanner .main-subpage {
	margin-top: 13.1rem;
	@include media-breakpoint-up(lg) {
		margin-top: 14.5rem;
	};
}
.border-bottom {
	  border-bottom: solid 1px $gray-800 !important;
}
.border-top {
	  border-top: solid 1px $gray-800 !important;
}

.mb-n5 {
	margin-top: -20px;
	@include media-breakpoint-up (md) {
		margin-top: -28px
	};
}
.mb-n8 {
	bottom: -49.2px;
	@include media-breakpoint-up (md) {
		bottom: -59.2px
	};
}
.flex-1 {
	flex: 1;
}
.flex-2 {
	flex: 2;
}
p.text-justify+ p {
	text-align: justify;
}
p:last-child, .mb-0+p {
	margin-bottom: 0 !important;
}
.d-flex+p {
	display: none;
}
.line-height {
	line-height: 1.6 !important
}

a {
	color: $color-primary;
	&:hover {
		color: $color-primary;
		text-decoration: underline;
	}
}
.lead {
		color: $color-primary;
		margin-bottom: 2.5rem !important ;
}
.lead--hero {
	width: 100%;
	margin: 0 auto;
	@include media-breakpoint-up(lg) {
		width: 29.875rem;
		margin: 0 auto;
	};
}
.h1--numbers {
	font-size: 4rem;
	margin-bottom: 0;
}
 h2, h3, h4, .display-3, h6 {
	// font-family: $font-primary;
	font-weight: 700;
	color: $color-primary;
}
h1 {
	font-size: 2.375rem;
	display: inline-block;
	line-height: normal;
	margin-bottom: 2rem;
	@include media-breakpoint-up(md) {
		font-size: 3.5rem ;
		margin-bottom: 4rem;
	};
}

h1 a {
	color: $gray-900;
	@include hover-focus-active {
		color: $gray-700;
		text-decoration: none;
	}
}
h2 {
	font-size: 1.75rem;
	@include media-breakpoint-up(md) {
		font-size: 2.625rem;
	};
}
h3 {
	font-size: 1.375rem;
	@include media-breakpoint-up(md) {
		font-size: 2rem;
	};
}
h3.group {
font-size:	2.25rem !important;
}

h4 {
	font-size: 1.375rem;
}
h5 {
	font-size: 1.20rem;
}
.font-size-12 {
	font-size: 0.75rem !important;
	line-height: 105%;
}
.font-size-20{
	font-size: 1.25rem !important;
}
.font-size-24{
	font-size: 1.5rem !important;
}
.font-size-28{
	font-size: 1.75rem !important;
}

.font-size-32{
	font-size: 2rem !important;
}
.font-size-36{
	font-size: 2.25rem !important;
}
.font-size-68{
	font-size: 4.25rem !important;
}
.feszeklako-position {
	bottom: -20px;
	right: -35px;
}
.mezolako-position {
	bottom: -15px;
	right: -33px;
}
.erdolako-position {
	bottom: -40px;
	right: -35px;
}
.tolako-position {
	bottom: -20px;
	right: -45px;
}

.opacity {
	opacity: .5;
}
.col-lg-4 {
	transition: opacity .3s ease-out;
}

.display-3 {
	letter-spacing: -2px;
	font-size: 2rem;
	@include media-breakpoint-up (sm) {
	font-size: 3.5rem;
	};
	@include media-breakpoint-up (md) {
	font-size: 4.5rem;
	};
}
.p-2-alt {
	padding: 0.25rem 0.5rem 0.2rem 0.5rem !important;
}
.poster {
	@include noGutter (-4px, 4px);
	.col-auto:first-child {
		flex: 1 0 100%;
		margin-bottom: 1rem;
		@include media-breakpoint-up (md) {
			flex: 1 0 65%;
			margin-bottom: 0;
		};
	}
	.col-auto:last-child {
		flex: 0 0 100%;
		@include media-breakpoint-up (md) {
			flex: 0 0 28.62%;

		};
	}
	padding-bottom: 3rem;
}

.container--top {
	.row {
		min-height: 16.5rem;
		align-items: center;
		padding: 1rem 0;
		@include media-breakpoint-up (md) {
			padding:0;
		};
	}
}
.shadow {
	box-shadow: 0 0.2rem 0.5rem rgba(0,0,0,0.24) !important
}

.container-fluid.bg-light {
	.container {
		padding-left: 0px;
		padding-right: 0px;
		@include media-breakpoint-up (md) {
			padding-right: 15px;
			padding-left: 15px;
		};
	}
}
ol, ul, dl {
	margin-bottom: 2rem;
}
.table  {
	font-family: $font-secondary-condens;
	thead th {
		border-top: 0;
		border-bottom: 3px solid $gray-200;
		text-transform: uppercase;
		font-weight: bold
	}
}
th {
	font-weight: normal;
}

.contact-us {
	h3 {
		margin-bottom: 1rem;
		@include media-breakpoint-up (sm) {
			margin-bottom: 1.5rem;
		}
	}
	address {
		margin-bottom: 1rem;
		@include media-breakpoint-up (sm) {
			margin-bottom: 3rem;
		}
	}
}
.embed-responsive {
	padding: 0 1rem !important
}

.text-decoration-none {
	@include hover-focus-active{
		text-decoration: none !important;
	}
}
.padding-zero {
	padding: 0 !important
}

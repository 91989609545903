.form-control {
	background-color: #FFF;
	height: 48px;
	box-shadow: 2px 6px 0px rgba(0, 0, 0, 0.20);
	margin-top: 0;
	border-radius: 1rem;
  border: none;
}
.form-group {
margin-bottom: 1.5rem;
  label {
    color: white;
    font-size: 1.125rem;
  }
}

.wpcf7-form div:nth-last-of-type(2) {
	margin-bottom: 0px !important
}

form .form-group:last-of-type {
  margin-bottom: 0 !important;
}
.wpcf7-textarea {
  height: 10rem;
}
.form-group:last-child {
	margin-bottom: 0 !important;
}


.wpcf7-not-valid-tip {
	background: $color-secondary;
	padding: 1rem;
	border-radius: 1rem;
	color: red;
	display: block;
	height: 48px;
	margin-top: 0rem;
	line-height: 100%;
	position: relative;
	&:before  {
		content: '';
		width: 1rem;
		height: 1rem;
		background: $color-secondary;
		position: absolute;
		top: -.5rem;
		transform: rotate(45deg);
		left: 1.5rem;
	}
}
.wpcf7 input {
	margin-right: .5rem;
}


// .owl-carousel .owl-item img, .user-center {
//       display: inline !important;
//       width: auto !important;
// }
// .owl-stage-outer {
//   overflow: hidden;
// }

/*-------------------------------------------
review caousel
-------------------------------------------*/

.owl-one {
  .owl-dots {
    display: none;
  }
  .owl-nav {
   position: absolute;
   // bottom: -80px;
   width: 100%;
   z-index: 0;
   @include flexContainer(row, center, center);

   .owl-prev, .owl-next {
     font-size: 4rem !important;
     position: relative;
     @include flexContainer(row, center, center);
     color: $light-color-gray !important;
     margin: 0 .5rem;
     &:hover {
       color: $color-secondary !important;
     }
   }
   .owl-prev {
   }
   .owl-next {
     transition: background-color .3s ease;
   }
 }
}

/*-------------------------------------------
gallery caousel
-------------------------------------------*/
.owl-two {
  .owl-stage-outer {
    z-index: 1;
    border-radius: .5rem;
  }
  .item {
    .row {
      .col-lg-8 {
        padding-bottom: 2px;
        @include media-breakpoint-up(lg) {
          padding-right: 0px;
          padding-bottom: 0px;
        };

        // border-right: solid 1px $color-tertiary;
      }
      .col-lg-4 {
        padding-left: 0px;
        @include media-breakpoint-up(lg) {
          padding-left: 2px;
        };
        // border-left: solid 1px $color-tertiary;
      }
    .row {
      .col-6:first-of-type {
          padding-right: 1px;
        @include media-breakpoint-up(lg) {
          padding-bottom: 1px;
          padding-right: 0px;
        };

        // border-bottom: solid 1px $color-tertiary;
      }
      .col-6:last-of-type {
          padding-left: 1px;
        @include media-breakpoint-up(lg) {
          padding-top: 1px;
          padding-left: 0;
        };
        // border-top: solid 1px $color-tertiary;
      }
    }
    }

      // @include noGutter (1px, 1px);

    // .row .row {
    //   @include noGutter (0px, 0px);
    // }
  }
  // .item:first-of-type {
  //   img {
  //     margin-bottom: 2px;
  //     @include media-breakpoint-up(lg) {
  //       margin-bottom: 0px;
  //     };
  //   }
  // }
  // .item .col-6:first-of-type {
  //   img {
  //     margin-bottom: 0px;
  //     @include media-breakpoint-up(lg) {
  //       margin-bottom: 2px;
  //     };
  //   }
  // }
    .owl-dots {
      text-align: center;
      margin-top: .5rem;
      @include absoluteCenter;
      .owl-dot {
        display: inline-block;
        span {
          width: .75rem;
          height: .75rem;
          margin: 5px .2rem;
          background: rgba($color-secondary, 0.5);
          display: block;
          -webkit-backface-visibility: visible;
          transition: opacity .2s ease;
          border-radius: 30px;
        }
      }
      .owl-dot.active {
        span {
          background: $color-secondary;
        }
      }
    }
    .owl-nav .owl-prev.disabled,
    .owl-nav .owl-next.disabled {
       display: none;
  }
     .owl-nav {
        position: absolute;
        top: 0;
        z-index: 0;
      width: 100%;
      height: 100%;
      @include flexContainer(row, center, center);

      .owl-prev, .owl-next {
        font-size: 2rem !important;
          position: absolute;
        width: 3.75rem;
        height: 3.75rem;
        background-color: rgba(255, 255, 255, 0.7) !important;
        border-radius: 150px;
        color: $color-secondary !important;
        @include flexContainer(row, center, center);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        &:hover {
          background-color: white !important;
        }
      }
      .owl-prev {
        left: -5rem;
      }
      .owl-next {
        right: -5rem;
        transition: background-color .3s ease;
      }
    }
}

.owl-item .item .row {
  img {
    width: 100% !important;
  }
}
.modal-content {
  .owl-nav {
   position: relative;
   @include media-breakpoint-up (md) {
     position: absolute;
     top: 0;
     z-index: 0;
   };
   top: -55px;
   z-index: 2;

   .owl-prev, .owl-next {
     position: relative;
     @include media-breakpoint-up (md) {
       position: absolute;
     };
   }
 }
}

.owl-carousel.owl-dis {
  .owl-dots {
    text-align: center;
    margin-top: .5rem;
    margin-bottom: 1rem;
    @include absoluteCenter;
    position: relative;
    .owl-dot {
      display: inline-block;
      span {
        width: .75rem;
        height: .75rem;
        margin: 5px .2rem;
        background: rgba($color-secondary, 0.5);
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity .2s ease;
        border-radius: 30px;
      }
    }
    .owl-dot.active {
      span {
        background: $color-secondary;
      }
    }
  }
}
/*-------------------------------------------
 caroucel disabled
-------------------------------------------*/

.owl-carousel.off {
    display: flex;
		flex-wrap: wrap;

}





//
//
// /*-------------------------------------------
// opening hero caroucel
// -------------------------------------------*/
//
// // .owl-one.owl-carousel {
// //   .owl-nav {
// //     position: absolute;
// //     width: 100%;
// //     .owl-prev, .owl-next {
// //     }
// //     .owl-prev {
// //       i {
// //         &:before {
// //         }
// //         &:hover {
// //           &:before {
// //           }
// //         }
// //       }
// //     }
// //
// //     .owl-next {
// //       i {
// //         }
// //         &:hover {
// //           &:before {
// //           }
// //         }
// //       }
// //     }
// //   }
// //   .owl-stage-outer {
// //     overflow: hidden;
// //   }
// // }
//
// /*-------------------------------------------
// all device caroucel (gallery)
// -------------------------------------------*/
//
// .owl-all-device {
//   .owl-stage-outer {
//     overflow: hidden;
//   }
//   .owl-nav {
//     .owl-prev, .owl-next {
//       @include media-breakpoint-up (md) {
//       height: 44vh;
//       };
//       @include media-breakpoint-up (lg) {
//       height: 53vh;
//       };
//       i {
//         margin-top: 0rem;
//       }
//     }
//   }
// }
//
// /*-------------------------------------------
// animation
// -------------------------------------------*/
// @keyframes spin {
//   0% {transform: translate(0px,0); }
//   50% {transform: translate(-5px,0); }
//   100% {transform: translate(0px,0); }
// }
// @keyframes spinRight {
//   0% {transform: translate(0px,0); }
//   50% {transform: translate(5px,0); }
//   100% {transform: translate(0px,0); }
// }
//
// /*-------------------------------------------
// stream carousel
// -------------------------------------------*/
// .owl-five.owl-carousel, .owl-four.owl-carousel {
//   .owl-nav {
//     position: absolute;
//     top: 0;
//     right: -2rem;
//     width: auto;
//     z-index: 5;
//     width: 2.5662rem;
//     height: 64%;
//     display: flex;
//
//     .owl-prev, .owl-next {
//       font-size: 2rem !important;
//       position: absolute;
//     }
//     .owl-prev {
//       right: 110px;
//       display: none;
//     }
//     .owl-next {
//       right: 0px;
//       height: 100%;
//       width: 2.5662rem;
//       background-color: $gray-800 !important;
//       color: $gray-550 !important;
//       transition: background-color .3s ease;
//       &:hover {
//         background-color: $gray-900 !important;
//         color: white !important;
//       }
//     }
//   }
//   .owl-stage-outer {
//     overflow: hidden;
//   }
// }
// .owl-five.owl-carousel {
//   .owl-nav {
//     height: 64%;
//     @include media-breakpoint-up(sm) {
//       height: 135px;
//     };
//     @include media-breakpoint-up(md) {
//       height: 186px;
//     };
//     @include media-breakpoint-up(lg) {
//       height:163px;
//     };
//     @include media-breakpoint-up(xl) {
//       height:197px ;
//     };
//   }
// }
// .smaller-owl-nav-height.owl-carousel {
//   .owl-nav {
//     height: 64%;
//     @include media-breakpoint-up(sm) {
//       height: 135px;
//     };
//     @include media-breakpoint-up(md) {
//       height: 186px;
//     };
//     @include media-breakpoint-up(lg) {
//       height:133px;
//     };
//     @include media-breakpoint-up(xl) {
//       height:161px ;
//     };
//   }
// }
//
// /*-------------------------------------------
// in development caroucel
// -------------------------------------------*/
// .owl-four.owl-carousel {
//   .owl-nav {
//     display: none;
//     @include media-breakpoint-up(sm) {
//       display: block;
//       height: 287px;
//     };
//     @include media-breakpoint-up(md) {
//       height: 388px;
//     };
//     @include media-breakpoint-up(lg) {
//       height:253px;
//     };
//     @include media-breakpoint-up(xl) {
//       height:304px;
//     };
//   }
// }
//
// .owl-two.owl-carousel, .owl-three.owl-carousel,  {
//   .owl-nav {
//     position: absolute;
//     top: -3.2rem;
//     right: 0;
//     width: auto;
//     z-index: 5;
//     .owl-prev, .owl-next {
//       width: 2.5rem;
//       height: 2.5rem;
//       background-color: $gray-800;
//       font-size: 1.75rem ;
//       color: $gray-550;
//       transition: background-color .3s ease;
//       &:hover {
//         background-color: $gray-900 !important;
//         color: white !important;
//       }
//     }
//     .owl-prev {
//       right: 110px;
//     }
//     .owl-next {
//       right: 0px;
//       margin-left: .25rem;
//     }
//   }
// }
//
// .owl-two.owl-carousel {
// .owl-stage-outer, .owl-stage, .owl-item {
//   height: 100% !important
// }
// .owl-item {
//   border-right: solid 4px black;
// }
// }
// .owl-three {
//   .card-title {
//   font-size: 1rem ;
//     @include media-breakpoint-up (md) {
//       font-size: 1.125rem;
//     };
//   }
//   .card-body {
//     border-right: 0;
//     border-bottom: 1px solid #dee2e6;
//     @include media-breakpoint-up (sm) {
//       border-right: 1px solid #dee2e6;
//       border-bottom: 0;
//     };
//   }
//   .card-img {
//     width: auto !important;
//   }
//   .card {
//   border-right: 4px solid $color-black;
//     @include media-breakpoint-up (sm) {
//       border-right: 0;
//     };
//   }
//   .card-text {
//     line-height: 1.2;
//   }
// }
// .owl-one {
//     background-color: $black;
//   h1 {
//     white-space: nowrap;
//   }
//   .owl-item  {
//
//   }
//   .owl-dots {
//     position: absolute;
//     bottom: 1rem;
//     left: 0;
//     right: 0;
//     text-align: center;
//     .owl-dot {
//       display: inline-block;
//       span {
//         width: .9rem;
//         height: .9rem;
//         margin: 5px .3rem;
//         background: rgba(255,255,255, 0.4);
//         display: block;
//         -webkit-backface-visibility: visible;
//         transition: opacity .2s ease;
//         border-radius: 30px;
//       }
//     }
//     .owl-dot.active {
//       span {
//         background: $primary;
//       }
//     }
//   }
//   .item {
//     // background-image: linear-gradient(180deg, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.00) 88%);
//     // background-size: 100% 250px;
//     // background-position: top;
//     // background-repeat: no-repeat;
//     // height: 60vh;
//     // width: 100%;
//     // overflow: visible;
//     // background-size: cover;
//     // background-position: center;
//     // // background-color: black;
//     // @include media-breakpoint-up(sm) {
//     //   height: 83vh;
//     // };
//   }
//   .carousel-bg {
//     // background-image: linear-gradient(180deg, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.00) 88%);
//     // background-size: 100% 250px;
//     // background-position: top;
//     // background-repeat: no-repeat;
//     width: 100%;
//     height: 110%;
//     overflow: visible;
//     background-size: cover;
//     background-position: center;
//     @include media-breakpoint-up(sm) {
//     };
//   }
//   .carousel-bg-overflow {
//     height: 60vh;
//     overflow: hidden;
//     @include media-breakpoint-up(sm) {
//       height: 83vh;
//     };
//   }
//   .h-100 {
//     height: 60vh !important;
//     @include media-breakpoint-up(sm) {
//       height: 83vh !important;
//     };
//     }
//     .container-fluid {
//       background-image: linear-gradient(180deg, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.00) 88%);
//       background-size: 100% 250px;
//       background-position: top;
//       background-repeat: no-repeat;
//     }
// }
//
//
// // .owl-lazy  {
// //   height: 100vh;
// //   background-size: cover;
// //   background-position: center;
// //   // opacity: .85!important;
// //   @include media-breakpoint-up (sm) {
// //     height: 640px;
// //   };
// // }
//
// .owl-one--gallery {
//   .owl-item, .item, .owl-lazy {
//     height: auto;
//   }
// }
//
// /*-------------------------------------------
//  sponsors  ScrollMagic
// -------------------------------------------*/
//
// .owl-carousel.off {
//     display: flex;
// 		flex-wrap: wrap;
//
// }
//
// #owl_about_main_slider-2 .card-body{
//   background-color: white;
//   margin-bottom: 2px;
//   @include flexContainer(row, center, center);
//   padding: 1.5rem;
//   @include media-breakpoint-up(sm) {
//     padding: 2.5rem ;
//   };
//   @include media-breakpoint-up(xl) {
//     padding: 3.5rem ;
//   };
//   a{
//     filter: grayscale(100%);
//     @include hover-focus {
//       filter: grayscale(0);
//     }
//   }
// }
// .owl-five.owl-carousel  {
//   .owl-stage-outer {
//     height: 100% !important
//   }
//   .owl-stage {
//       display: flex;
//       flex-wrap: wrap;
//       height: 100%
//   }
//   .owl-item {
//       display: flex;
//        height: auto !important;
//        @include media-breakpoint-up(sm) {
//         height: 100% !important;
//        };
//
//   }
//   .col-12 {
//     padding-right:0;
//     @include media-breakpoint-up(sm) {
//      padding-right:15px;
//     };
//   }
// }
//
// // .owl-carousel .owl-item .card {
// //     width: auto;
// //     height: 100%;
// // }

/*-------------------------------------------
gallery modal
-------------------------------------------*/
h2.modal-title{
	line-height: 115% !important;
}
.modal-dialog--gallery {
	// max-width: 95%;
	// @include media-breakpoint-up (sm) {
	// 	max-width: 70%;
	// };
	.modal-content{
		border-radius: 0;
		.modal-body {
			padding: 0;
			background-color: black;
			.owl-nav {
				display: none;
				@include media-breakpoint-up (sm) {
					display: flex;
				};
			}
			.owl-dots {
					display: none;
			}
		}
		.modal-footer {
			border-top: none;
			@include flexContainer (row, center, space-between);
			padding: 0;
			border: 1px solid white;
		.modal-title {
			font-size: 1.2rem;
			margin-left: 1rem;
      color: $black;
		}
		.modal-title, .modal-numbers {
			line-height: 1.15;
		}
		.modal-numbers {
			@include flexContainer (row, center, center);
			p {
				margin-bottom: 0;
				margin-right: 1rem;
				color: $color-tertiary;
			}
		}
		}
	}
}
.modal-dialog--commercials {

	.modal-body {
		p, h3 {
			color: $color-black;
		}
	}
}

.modal-dialog {
  max-width: auto;
  @include media-breakpoint-up (sm) {
    max-width: 85%;
  };
  @include media-breakpoint-up (lg) {
    max-width: 920px;
  };
}

.modal-content {
  border-radius: 0;
  border: none;
  .close {
    text-shadow: none;
    font-size: 2.5rem;
		padding: 0;
  }
  .modal-header {
    border-bottom: 0px;
  }

  .modal-header, .modal-body {
    // padding: 1rem  1rem 0 1rem;
    @include media-breakpoint-up (md) {
      padding: 2.5rem 2.5rem 0 2.5rem;
    };
  }
}

.modal-dialog--film-trailer {
  .modal-content {
    background-color: black;
  }
  .modal-header {
    color: white;
  }
  .close {
    color: white;
    text-shadow: none;
    font-size: 2.5rem
  }
  .modal-body iframe {
    width: 100%;
  }
}

// notes
// @include media-breakpoint-up(sm) {
//     // Styles
// }
// @include media-breakpoint-up(sm) {
//     // Styles
// }

//DEV
// .user-modal .login { display: none !important; }
//.user-modal .register { display: none !important; }


// hide register/login
.logged-in .user-modal , .logged-in .user-modal  {
    .register, .login, #user-modal-close {
        display: none;
    }
}

// @font-face {
//     font-family: 'bungeeregular';
//     src: url('../webfonts/bungee-regular-webfont.eot');
//     src: url('../webfonts/bungee-regular-webfont.eot?#iefix') format('embedded-opentype'),
//          url('../webfonts/bungee-regular-webfont.woff2') format('woff2'),
//          url('../webfonts/bungee-regular-webfont.woff') format('woff'),
//          url('../webfonts/bungee-regular-webfont.ttf') format('truetype'),
//          url('../webfonts/bungee-regular-webfont.svg#bungeeregular') format('svg');
//     font-weight: normal;
//     font-style: normal;
//
// }

// zolika!!!

// @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700|Roboto:400,700');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap&subset=latin-ext');

/* font proxima nova */
@font-face {
  font-family: 'Proxima Nova bold';
  src: url('../../../web/resources/fonts/ProximaNova-Bold.eot'); /* IE9 Compat Modes */
  src: url('../../../web/resources/fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../../web/resources/fonts/ProximaNova-Bold.woff') format('woff'), /* Modern Browsers */
       url('../../../web/resources/fonts/ProximaNova-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../../../web/resources/fonts/ProximaNova-Bold.svg#d1e00a8fb1fd9b428ab6b90409479fde') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  700;
}
@font-face {
  font-family: 'Proxima Nova Lt';
  src: url('../../../web/resources/fonts/ProximaNova-Semibold.eot'); /* IE9 Compat Modes */
  src: url('../../../web/resources/fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../../web/resources/fonts/ProximaNova-Semibold.woff') format('woff'), /* Modern Browsers */
       url('../../../web/resources/fonts/ProximaNova-Semibold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../../../web/resources/fonts/ProximaNova-Semibold.svg#67a6c9f6d7dc8f35ecb08de766ea56f0') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  700;
}
@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('../../../web/resources/fonts/ProximaNova-Regular.eot'); /* IE9 Compat Modes */
  src: url('../../../web/resources/fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../../web/resources/fonts/ProximaNova-Regular.woff') format('woff'), /* Modern Browsers */
       url('../../../web/resources/fonts/ProximaNova-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../../../web/resources/fonts/ProximaNova-Regular.svg#2c1459d58560f08b4b0586fbe80aa4cd') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  400;
}

@import "bootstrap";
@import "base/variables";
@import "base/mixins";
@import "base/base";
@import "layout/header";
@import "layout/footer";
@import "modules/buttons";
@import "modules/navigation";
@import "modules/cards";
@import "modules/carousel";
@import "modules/forms";
// @import "modules/pagination";
// @import "modules/badge";
@import "modules/tabs";
@import "modules/modal";

.navbar-collapse {
  flex-grow: 0 !important;
  ul:first-child {
    li:nth-last-child(1) {
      // background: red;
      order: 1;
    }
    li:nth-last-child(2) {
      // background: purple;
      order: 2;
    }
  }
}


.flex--nav {
  @include flexContainer(column, flex-start, space-between);
  @include media-breakpoint-up(lg) {
  @include flexContainer(row, center, space-between);
  };
}
.home .flex--nav {
  @include flexContainer(column, flex-end, center);
  @include media-breakpoint-up(lg) {
  @include flexContainer(row, center, center);
  };
}
.home .flex--nav-2 {
  @include flexContainer(row, center, flex-end);
  @include media-breakpoint-up(lg) {
  };
}
.flex--nav-2 {
  @include flexContainer(row, center, space-between);
  @include media-breakpoint-up(lg) {
  };
}
.navbar-collapse {
  flex-grow: 0 !important;
  ul:first-child {
    li:nth-last-child(1) {
      // background: red;
      order: 1;
    }
    li:nth-last-child(2) {
      // background: blue;
      order: 2;
    }
  }
}
.home {
  .navbar-brand {
    display: none;
  }
}
.navbar-expand-lg {
 justify-content: center !important;
}

.navbar {
  padding: 1rem 0 0rem 0;
   @include media-breakpoint-up (lg) {
     padding: 1.5rem 0 3rem 0;
   }
}

.navbar-nav {
  width: 100%;
	padding: 0 0px;
 @include media-breakpoint-up (lg) {
   @include topNav;
    justify-content: center;
    border-radius: 500px;
    background-color: $color-primary;
    width:auto;
    padding: 0 15px;

 };
}

.navbar-nav>li>a {
	font-size: 16px;
	color: lighten( $color-primary, 35% ) !important;
  position: relative;
  display: block;
  @include topNav;
   justify-content: center;
   border-radius: 500px;
   background-color: $color-primary;
   width: 100%;
   margin-bottom: .5rem;
   box-shadow: none;
  @include media-breakpoint-up (lg) {
   background-color: transparent;
   box-shadow: none;
   margin-bottom: 0rem;
 }
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: .8rem;
  padding-left: .8rem; //sadf
}
.navbar-nav > li > a:hover, .navbar-nav > li > a:focus {
	color: #FFF !important;
}
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.active>a:focus{
	color: #FFF!important;
	background-color: transparent!important;
	background: -moz-linear-gradient(top,  rgba(165,117,34,1) 0%, rgba(165,117,34,0) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(165,117,34,1)), color-stop(100%,rgba(165,117,34,0))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  rgba(165,117,34,1) 0%,rgba(165,117,34,0) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  rgba(165,117,34,1) 0%,rgba(165,117,34,0) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  rgba(165,117,34,1) 0%,rgba(165,117,34,0) 100%); /* IE10+ */
	background: linear-gradient(to bottom,  rgba(165,117,34,1) 0%,rgba(165,117,34,0) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#990d00', endColorstr='#00990d00',GradientType=0 ); /* IE6-9 */
}
.navbar-nav.jelentkezes {
	// margin-top: 17px;
	margin-left: 0rem;
	background-color:$color-secondary ;
  width: 100%;
	height:40px;
  border-radius: 160px;
  box-shadow: 0;
  @include media-breakpoint-up (lg) {
    	width:120px;
      margin-left: 1rem;
      box-shadow: 0px 4px 0px darken( $color-secondary, 10% );

  };
	}
.navbar-nav.jelentkezes a {
	color: lighten( $button, 45% ) !important;
  background-color:$color-secondary ;
}
.collapse {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up (lg) {
    justify-content: center;
    flex-direction: row;
  } ;
}
.home .navbar-nav.jelentkezes {
  display: none;
}

.navbar-toggler {
  background: $button;
  border: none;
  margin-right: 0;
  border-radius: 6px;
}

.nav-link {
    padding: .75rem 1.5rem !important;
    @include media-breakpoint-up(lg) {
      padding: .5rem .8rem !important;
    };
}

footer#footer {
  .navbar-nav {
    flex-direction: column;
    padding: 0;
    margin-left: -15px;
    margin-right: -15px;
    @include media-breakpoint-up (sm) {
      flex-direction: row;
  };
  }
  .navbar-nav > li > a:hover, .navbar-nav > li > a:focus {
  	text-decoration: underline;
  }

    .row+.row+.row  {
    color:$color-primary !important;
    opacity: .5 !important;

  }
    .navbar-nav > li > a {
      color:$color-primary !important;
      padding: 0 15px !important;
      white-space: nowrap;
    }
    .nav--social {
      .nav-link {
        margin: 0 .5rem;
        border-radius: 50px;
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-primary;
        color: white;
        font-size: 1.5rem;
        padding: 0
      }
    }
}
.nav--social {
  .nav-link {
    width: 3.625rem;
    height: 3.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@mixin paddingMarginZero {
	padding:0px;
	margin:0px;
}

@mixin flexContainer ($flex-direction, $align-items, $justify-content)  {
display: flex;
flex-direction: $flex-direction;
align-items: $align-items;
justify-content: $justify-content;
}

@mixin absoluteCenter {
	position: absolute;
	left: 0;
	right: 0;
	margin-left:  auto;
	margin-right: auto;
}
@mixin bgImage ($image) {
background-image: url($image);
background-repeat: no-repeat;
background-position: center center;
background-size: cover;
}
@mixin roundEdge ($radius){
	border-radius: $radius;
}

// media queries
@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}
@mixin laptop {
  @media (min-width: #{$laptop-width}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin noGutter ($margin, $padding) {
	.no-gutters {
		margin-right: $margin;
		margin-left: $margin;
	>.col, >[class*="col-"] {
		padding-right: $padding;
		padding-left: $padding;
	}
	}
}
@mixin topNav {
		 -webkit-box-shadow: 0px 4px 0px darken( $color-primary, 10% ); /* Android 2.3+, iOS 4.0.2-4.2, Safari 3-4 */
          box-shadow: 0px 4px 0px darken( $color-primary, 10% ); /* Chrome 6+, Firefox 4+, IE 9+, iOS 5+, Opera 10.50+ */
		  border-radius: 12px; /* Android 2.1+, Chrome, Firefox 4+, IE 9+, iOS 4+, Opera 10.50+, Safari 5+ */
  /* useful if you don't want a bg color from leaking outside the border: */
  background-clip: padding-box; /* Android 2.2+, Chrome, Firefox 4+, IE 9+, iOS 4+, Opera 10.50+, Safari 4+ */
}
@mixin button {
		 -webkit-box-shadow: 0px 4px 0px darken( $button, 10% ); /* Android 2.3+, iOS 4.0.2-4.2, Safari 3-4 */
          box-shadow: 0px 4px 0px darken( $button, 10% ); /* Chrome 6+, Firefox 4+, IE 9+, iOS 5+, Opera 10.50+ */
		  border-radius: 12px; /* Android 2.1+, Chrome, Firefox 4+, IE 9+, iOS 4+, Opera 10.50+, Safari 5+ */
  /* useful if you don't want a bg color from leaking outside the border: */
  background-clip: padding-box; /* Android 2.2+, Chrome, Firefox 4+, IE 9+, iOS 4+, Opera 10.50+, Safari 4+ */
}


//colors
$color-primary: #A91F68;
$color-secondary: #392665;
$color-tertiary: #D2EEFC;
$color-black: #000000;
$color-white: #FFFFFF;
$middleYellow: #CD9137;
$button: $color-secondary;


$light-color-gray: lighten($color-black, 80% );
$dark-color-gray: lighten($color-black, 20% );
$mid-color-gray: lighten($color-black, 50% );
$mid-color2-gray: lighten($color-black, 60% );
$text-color-gray: lighten($color-black, 30% );

$light-color-primary: lighten($color-primary, 10% );
$dark-color-primary: darken($color-primary, 10% );

$light-color-secondary: lighten($color-secondary, 10% );
$dark-color-secondary: darken($color-secondary, 10% ); 

$light-color-white: rgba($color-white,0.40);
$light-2-color-white: rgba($color-white,0.80);
$light-3-color-white: rgba($color-white,0.50);
$light-4-color-white: rgba($color-white,0.65);
$light-5-color-white: rgba($color-white,0.20);

$fade-color-primary: tint( $color-primary, 20%);
$fade-color-2-primary: tint( $color-primary, 40%);

$fade-color-secondary: tint( $color-secondary, 50%);

$opacity-color-primary: rgba($color-primary,0.95);
$opacity2-color-primary: rgba($color-primary,0.80);

//fonts
$font-primary: 'Proxima Nova Rg', sans-serif;
$font-primary-thin: 'Proxima Nova Lt', sans-serif;
$font-primary-bold:  'Proxima Nova bold', sans-serif;

$font-secondary: Roboto, sans-serif;
$font-secondary-condens: 'Roboto Condensed', sans-serif;

//media query
$tablet-width: 768px;
$laptop-width: 992px;
$desktop-width: 1200px;

$border-radius-buttom: 12px;

button:focus {
  outline: none;
}

.btn {
  font-family: $font-primary;
  font-weight: 700;
  font-size: 1.25rem;
  border-radius: 10rem;
  padding: .45rem 1.8rem;
  border: 2px solid transparent;
  display: inline-flex;
  i {
    font-size: 1.8rem;
    margin-left: .5rem;
  }
}
.btn-primary {
  background-color: $color-secondary;
  border-color: $color-secondary;
  box-shadow: 0px 6px 0px $dark-color-secondary;
  @include hover-focus-active{
    background-color: $dark-color-secondary;
    border-color: $dark-color-secondary;
  }
}
.btn-secondary {
  background-color: $color-tertiary;
  border-color: $color-primary;
  box-shadow: 0px 6px 0px $color-primary;
  color: $color-primary;
  @include hover-focus-active{
    background-color: $color-primary;
    border-color: $color-primary; 
  }

}
.btn-link {
  font-size: 1rem;
  font-weight: bold;
  padding: 0;
  box-shadow: none !important;
  text-decoration: none;
  &:hover, &:visited, &:active {
    text-decoration: none !important;
  }
}


.accordion {
  .btn {
    white-space: normal;
  }
}
